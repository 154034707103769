import React, { useContext, useRef } from 'react';

// context
import { DeviceContext } from 'contexts/DeviceContext';

// types
import type { WCmsPageImageView, WLengthView } from '@zola/svc-web-api-ts-client';
import type { MultiPageHeroVariantProps } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/types';

// utils
import { getWebsitePlaceholderAsset } from 'components/manage/EditWebsite/common/HeroCustomizer/utils';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';

// components
import WebsiteSlideshow from 'components/publicWebsiteV2/common/WebsiteSlideshow';

// styles
import { BaseHero } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';
import {
  DesktopImagesContainer,
  SevenByFiveImageSized,
  FiveBySevenImageSized,
  SEVEN_BY_FIVE_IMAGE_WIDTH_PERCENTAGE,
  FIVE_BY_SEVEN_IMAGE_WIDTH_PERCENTAGE,
} from 'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroSideBySide/MultiPageHeroSideBySide.styles';

const MultiPageHeroSideBySide: React.FC<MultiPageHeroVariantProps> = ({
  images,
  inPreview,
  overrideMobileHeight,
}) => {
  const { device } = useContext(DeviceContext);

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  const sevenByFivePhoto = isDesktop
    ? images?.find(
        image => image?.size === (('SEVEN_BY_FIVE' as unknown) as WCmsPageImageView.SizeEnum)
      )
    : images?.find(
        image =>
          image?.size === (('SQUARE' as unknown) as WCmsPageImageView.SizeEnum) &&
          image.position === 1
      );
  const fiveBySevenPhoto = isDesktop
    ? images?.find(
        image => image?.size === (('FIVE_BY_SEVEN' as unknown) as WCmsPageImageView.SizeEnum)
      )
    : images?.find(
        image =>
          image?.size === (('SQUARE' as unknown) as WCmsPageImageView.SizeEnum) &&
          image.position === 2
      );

  const slideshowImages = [
    sevenByFivePhoto
      ? sevenByFivePhoto?.image_url
      : getWebsitePlaceholderAsset('SIDE_BY_SIDE', isDesktop, 1),
    fiveBySevenPhoto
      ? fiveBySevenPhoto?.image_url
      : getWebsitePlaceholderAsset('SIDE_BY_SIDE', isDesktop, 2),
  ];

  return isDesktop ? (
    <BaseHero
      overrideMobileHeight={overrideMobileHeight}
      data-testid="MultiPageHeroSideBySide"
      ref={containerRef}
    >
      <DesktopImagesContainer>
        <SevenByFiveImageSized
          src={
            sevenByFivePhoto
              ? sevenByFivePhoto?.image_url
              : getWebsitePlaceholderAsset('SIDE_BY_SIDE', isDesktop, 1)
          }
          alt="Hero image 1"
          containerWidth={containerWidth}
          assetStyles={{
            responsive_size: {
              desktop: {
                width: {
                  unit: ('PERCENTAGE' as unknown) as WLengthView.UnitEnum,
                  value: SEVEN_BY_FIVE_IMAGE_WIDTH_PERCENTAGE,
                },
              },
            },
          }}
        />
        <FiveBySevenImageSized
          src={
            fiveBySevenPhoto
              ? fiveBySevenPhoto?.image_url
              : getWebsitePlaceholderAsset('SIDE_BY_SIDE', isDesktop, 2)
          }
          alt="Hero image 2"
          containerWidth={containerWidth}
          assetStyles={{
            responsive_size: {
              desktop: {
                width: {
                  unit: ('PERCENTAGE' as unknown) as WLengthView.UnitEnum,
                  value: FIVE_BY_SEVEN_IMAGE_WIDTH_PERCENTAGE,
                },
              },
            },
          }}
        />
      </DesktopImagesContainer>
    </BaseHero>
  ) : (
    <BaseHero overrideMobileHeight={overrideMobileHeight} data-testid="MultiPageHeroSideBySide">
      <WebsiteSlideshow slideshowImages={slideshowImages} inPreview={inPreview} />
    </BaseHero>
  );
};

export default MultiPageHeroSideBySide;
